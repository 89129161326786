<template>
  <div class="gallery-preview">
    <img
      v-if="fileType === 'image'"
      :src="file.link"
      alt="Image preview"
    />
    <video v-if="fileType === 'video'" class="preview-box">
      <source :src="file.link">
      Your browser does not support the video tag.
    </video>
    <div
      v-if="fileType === 'generic'"
      class="preview-box flex flex-col justify-center items-center gap-8 py-8"
    >
      <div>
        <i class="far fa-file"></i>
      </div>
      <div>{{ file.filename }}</div>
    </div>
  </div>
</template>

<script>
import { getFileType } from '@/helpers';

export default {
  name: 'Preview',
  components: {},
  computed: {
    fileType() {
      return getFileType(this.file);
    },
  },
  props: {
    file: Object,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
  .gallery-preview {
    border-radius: 25px;
    display: flex;
    height: 100%;
    max-height: 250px;
    background-color: #eee;

    img {
      border-radius: 10px;
      object-fit: cover;
      will-change: transform;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      width: 100%;
    }

    i {
      font-size: 32px;
    }

    .preview-box {
      width: 100%;
      padding: 15px 30px;
      border: 1px solid #eee;
      border-radius: 10px;
      background-color: #eee;
    }
  }
</style>
